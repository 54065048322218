import React from "react";
import { Admin, Resource, fetchUtils, Options } from "react-admin";
import authProvider from "../res/authProvider";
import { API_URL } from "../res/cwbMethods";
import dataProvider from "../res/dataProvider";
import { extendedTheme } from "../res/theme";
import { EgressCryptoList, EgressFiatList } from "../screens/Egress/List";
import { IngressCryptoList, IngressFiatList } from "../screens/Ingress/List";
import { KycList } from "../screens/KYC/List";
import { TokensList } from "../screens/Tokens/List";
import { MaintenanceList } from "../screens/Maintenance/List";
import { EmailsList } from "../screens/Whitelist/EmailsList";
import { DomainsList } from "../screens/Whitelist/DomainsList";
import { OperatorsList } from "../screens/Operators/List";
import Layout from "../components/Layout/Layout";

const httpClient = (url: string, options?: Options) => {
    options = options ?? {};

    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const token = localStorage.getItem("auth");
    (options.headers as Headers).set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

function App() {
    return (
        <Admin
            title="Coinweb Backoffice"
            dataProvider={dataProvider(API_URL, httpClient)}
            authProvider={authProvider}
            theme={extendedTheme}
            layout={Layout}
            requireAuth
        >
            <Resource name="operators" list={OperatorsList} />
            <Resource
                name="egress/crypto"
                list={EgressCryptoList}
                options={{ label: "Crypto Egress" }}
            />
            <Resource name="egress/fiat" list={EgressFiatList} options={{ label: "Fiat Egress" }} />
            <Resource
                name="ingress/crypto"
                list={IngressCryptoList}
                options={{ label: "Crypto Ingress" }}
            />
            <Resource
                name="ingress/fiat"
                list={IngressFiatList}
                options={{ label: "Fiat Ingress" }}
            />
            <Resource name="kyc" list={KycList} />
            <Resource name="maintenance" list={MaintenanceList} />
            <Resource name="tokens" list={TokensList} />
            <Resource
                name="whitelist/emails"
                list={EmailsList}
                options={{ label: "Whitelisted emails" }}
            />
            <Resource
                name="whitelist/domains"
                list={DomainsList}
                options={{ label: "Whitelisted domains" }}
            />
        </Admin>
    );
}

export default App;

import React from "react";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import PeopleIcon from "@mui/icons-material/People";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const MyMenu = () => (
    <MultiLevelMenu initialOpen>
        <MultiLevelMenu.Item
            name="Operators"
            to="/operators"
            label="Operators"
            icon={<PeopleIcon />}
        />
        <MultiLevelMenu.Item name="kyc" to="/kyc" label="KYC" icon={<ContactPageIcon />} />
        <MultiLevelMenu.Item
            name="ingress"
            to={"#"}
            label="Ingress"
            icon={<CurrencyExchangeIcon />}
        >
            <MultiLevelMenu.Item name="ingress.crypto" to={"/ingress/crypto"} label="Crypto" />
            <MultiLevelMenu.Item name="ingress.fiat" to={"/ingress/fiat"} label="Fiat" />
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="egress" to={"#"} label="Egress" icon={<CurrencyExchangeIcon />}>
            <MultiLevelMenu.Item name="egress.crypto" to={"/egress/crypto"} label="Crypto" />
            <MultiLevelMenu.Item name="egress.fiat" to={"/egress/fiat"} label="Fiat" />
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="whitelist" to={"#"} label="Whitelist" icon={<DangerousIcon />}>
            <MultiLevelMenu.Item name="whitelist.emails" to={"/whitelist/emails"} label="Emails" />
            <MultiLevelMenu.Item
                name="whitelist.domains"
                to={"/whitelist/domains"}
                label="Domains"
            />
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="tokens" to="/tokens" label="Tokens" icon={<PlaylistAddIcon />} />
        <MultiLevelMenu.Item
            name="maintenance"
            to="/maintenance"
            label="Maintenance"
            icon={<EngineeringIcon />}
        />
    </MultiLevelMenu>
);

export default MyMenu;

import React, { useMemo } from "react";
import {
    ArrayField,
    ChipField,
    Datagrid,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useResourceContext,
    WrapperField,
} from "react-admin";
import FileDialog from "../../components/KYC/FileDialog";
import { KycComponent } from "../../res/types";
import { DateTime } from "luxon";

export const KycComponentDetails = () => {
    const record = useRecordContext() as KycComponent;
    const fields = useMemo(
        () =>
            Object.keys(record.component.data ?? {}).map((field) => (
                <TextField
                    key={field}
                    label={field.replace(/_/gi, " ")}
                    source={`component.data.${field}`}
                />
            )),
        [record.component.data],
    );

    return (
        <Datagrid data={[record]} total={1} bulkActionButtons={false}>
            {fields}
        </Datagrid>
    );
};

export const KycExpand = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    return (
        <Show title={" "} resource={resource} id={record.id}>
            <SimpleShowLayout>
                <ArrayField source="components" label="">
                    <Datagrid
                        bulkActionButtons={false}
                        rowClick="expand"
                        isRowExpandable={(record: KycComponent) => Boolean(record.component.data)}
                        expand={<KycComponentDetails />}
                        sx={{
                            "& .column-action": { textAlign: "right" },
                        }}
                    >
                        <FunctionField
                            render={(record: KycComponent) => (
                                <>
                                    <ChipField source="status.component" />
                                    {record.component.documents?.ref && (
                                        <ChipField source="component.documents.type" />
                                    )}
                                </>
                            )}
                        />
                        <FunctionField
                            render={(record: KycComponent) =>
                                record?.status?.at
                                    ? DateTime.fromISO(record.status.at).toFormat(
                                          "dd.MM.yyyy, hh:mm:ss",
                                      )
                                    : ""
                            }
                        />
                        <ChipField label="Status" source="status.status" />
                        <WrapperField label="File" source="file">
                            <FileDialog />
                        </WrapperField>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

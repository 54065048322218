import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";
import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import { ActionOption, TokenAction } from "../../res/types";
import BulkActions from "../../components/BulkActions";

const bulkActions: ActionOption<TokenAction>[] = [
    {
        type: "sufix",
        action: "enable",
        title: "Enable",
        content: "Are you sure you want to enable the token?",
        buttonLabel: "Enable",
    },
    {
        type: "sufix",
        action: "disable",
        title: "Disable",
        content: "Are you sure you want to disable the token?",
        buttonLabel: "Disable",
    },
];

export const TokensList = () => (
    <>
        <List hasCreate>
            <Datagrid bulkActionButtons={<BulkActions actions={bulkActions} />}>
                <NumberField label="ID" source="id" />
                <TextField source="name" sortable={false} />
                <ChipField source="token_hash" />
                <DateField source="disabled_at" showTime />
                <EditButton />
            </Datagrid>
        </List>
        <CreateDialog>
            <SimpleForm>
                <TextInput source="name" validate={required()} />
                <TextInput source="token_hash" validate={required()} />
            </SimpleForm>
        </CreateDialog>
        <EditDialog>
            <SimpleForm>
                <TextInput source="name" validate={required()} />
            </SimpleForm>
        </EditDialog>
    </>
);

import React, { useMemo } from "react";
import { Datagrid, TextField, useRecordContext } from "react-admin";
import { EgressCryptoRecord, EgressFiatRecord } from "../../res/types";

export const EgressExpand = () => {
    const record = useRecordContext() as EgressCryptoRecord | EgressFiatRecord;
    const fields = useMemo(
        () =>
            Object.keys(record.metadata ?? {}).map((field) => (
                <TextField
                    key={field}
                    label={field.replace(/_/gi, " ")}
                    source={`metadata.${field}`}
                />
            )),
        [record.metadata],
    );

    return (
        <Datagrid data={[record]} total={1} bulkActionButtons={false}>
            <TextField source="target.address" sortable={false} />
            {fields}
        </Datagrid>
    );
};

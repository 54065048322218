import { stringify } from "query-string";
import { fetchUtils, DataProvider, UpdateResult } from "react-admin";
import { getPKforResource } from "./utils";
import { DateTime } from "luxon";

export type Resource =
    | "kyc"
    | "egress/fiat"
    | "egress/crypto"
    | "ingress/crypto"
    | "ingress/fiat"
    | "whitelist/emails"
    | "whitelist/domains";
type Filter = { field: string; operator: string; value: string | number };

function parseFilters(filters: Record<string, string>, defaultListOp = "=") {
    const result: Filter[] = [];
    Object.keys(filters).forEach(function (key) {
        // key: the name of the object key
        const splitKey = key.split("@");
        const field = splitKey[0];
        const operator = splitKey.length == 2 ? splitKey[1] : defaultListOp;
        const value = operator.includes("~~") ? `%${filters[key]}%` : filters[key];
        const filter: Filter = { field, operator, value };

        result.push(filter);
    });

    return result;
}

export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader = "Content-Range",
): DataProvider => ({
    getList: (resource, params) => {
        const pk = getPKforResource(resource as Resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { before, after, ...filters } = params.filter;
        const filter = parseFilters(filters);

        const query = {
            sort: JSON.stringify([field, order]),
            limit: perPage,
            offset: (page - 1) * perPage,
            filter: JSON.stringify(filter),
            before: DateTime.fromISO(before).endOf("day").toISO(),
            after: DateTime.fromISO(after).startOf("day").toISO(),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options = {};

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
                );
            }

            return {
                data: json.map((resource: any) => ({
                    ...resource,
                    id: resource[pk],
                })),
                total:
                    countHeader === "Content-Range"
                        ? parseInt(headers.get("content-range")?.split("/").pop() ?? "", 10)
                        : parseInt(headers.get(countHeader.toLowerCase()) ?? ""),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            const pk = getPKforResource(resource as Resource);
            return {
                data: { ...json, id: json[pk] },
            };
        }),

    getMany: (resource, params) => {
        const pk = getPKforResource(resource as Resource);
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: json.map((resource: any) => ({
                ...resource,
                id: resource[pk],
            })),
        }));
    },

    getManyReference: (resource, params) => {
        const pk = getPKforResource(resource as Resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options =
            countHeader === "Content-Range"
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                  }
                : {};

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
                );
            }
            return {
                data: json.map((resource: any) => ({
                    ...resource,
                    id: resource[pk],
                })),
                total:
                    countHeader === "Content-Range"
                        ? parseInt(headers.get("content-range")?.split("/").pop() ?? "", 10)
                        : parseInt(headers.get(countHeader.toLowerCase()) ?? ""),
            };
        });
    },

    update: (resource, params) => {
        const { id, data, meta } = params;
        console.log(data);

        return httpClient(
            `${apiUrl}/${resource}/${meta?.component ? meta.component + "/" : ""}${id}/${
                meta?.action ?? ""
            }`,
            {
                method: "PATCH",
                body: JSON.stringify(data),
            },
        ).then((res) => {
            if (res.json) return { data: res.json };
            return { data } as UpdateResult;
        });
    },

    updateMany: (resource, params) => {
        const { ids, data, meta } = params;
        console.log(data);

        return Promise.all(
            ids.map((id) =>
                httpClient(
                    `${apiUrl}/${resource}/${meta?.component ? meta.component + "/" : ""}${id}/${
                        meta?.action ?? ""
                    }`,
                    {
                        method: "PATCH",
                        body: JSON.stringify(data),
                    },
                ),
            ),
        ).then((responses) => ({
            data: responses.map(({ json }) => {
                return json;
            }),
        }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            const pk = getPKforResource(resource as Resource);
            return { data: { ...json, ...params.data, id: params.data[pk] } };
        }),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
            headers: new Headers({
                "Content-Type": "text/plain",
            }),
        }).then(({ json }) => {
            const pk = getPKforResource(resource as Resource);
            return { data: { ...json, id: json[pk] } };
        }),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: "DELETE",
                    headers: new Headers({
                        "Content-Type": "text/plain",
                    }),
                }),
            ),
        ).then((responses) => ({
            data: responses.map(({ json }) => {
                const pk = getPKforResource(resource as Resource);
                return json[pk];
            }),
        })),
});

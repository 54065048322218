import React from "react";
import { Layout, LayoutProps } from "react-admin";
import MyMenu from "./Menu";
import { AppLocationContext } from "@react-admin/ra-navigation";

const MyLayout = (props: LayoutProps) => (
    <AppLocationContext>
        <Layout {...props} menu={MyMenu} />
    </AppLocationContext>
);

export default MyLayout;

import axios from "axios";

export const API_URL = process.env.REACT_APP_SERVER_API as string;
export const AUTH_KEY = "auth";

export const customAction = async (path: string) => {
    try {
        const JWT = localStorage.getItem(AUTH_KEY);
        const { data } = await axios.patch(`${API_URL}/${path}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${JWT}`,
            },
        });
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorCode = error.code;
            console.log("Error in ", path, errorCode);
            throw errorCode;
        } else {
            throw "An unexpected error occurred";
        }
    }
};

export const downloadKYCDocument = async (filePath: string) => {
    try {
        const JWT = localStorage.getItem(AUTH_KEY);
        const { data, headers } = await axios.get(`${API_URL}/kyc/download`, {
            params: {
                ref: filePath,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${JWT}`,
            },
            responseType: "arraybuffer",
        });
        return { file: data, type: headers["content-type"] };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorCode = error.code;
            console.log("Error in ", errorCode);
            throw errorCode;
        } else {
            throw "An unexpected error occurred";
        }
    }
};

import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import React from "react";
import {
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    List,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from "react-admin";

const filters = [
    <TextInput key="email" label="Email" source="email@~~" />,
    <DateInput label="From" key="after" source="after" alwaysOn />,
    <DateInput label="To" key="before" source="before" alwaysOn />,
];

export const EmailsList = () => (
    <>
        <List sort={{ field: "created_at", order: "DESC" }} filters={filters} hasCreate>
            <Datagrid bulkActionButtons={false}>
                <TextField source="email" />
                <DateField source="created_at" showTime />
                <DateField source="disabled_at" showTime />
                <TextField source="disable_reason" />
                <EditButton />
            </Datagrid>
        </List>
        <CreateDialog>
            <SimpleForm>
                <TextInput source="email" validate={required()} />
            </SimpleForm>
        </CreateDialog>
        <EditDialog>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }
            >
                <TextInput source="email" validate={required()} />
                <SelectInput
                    source="status"
                    validate={required()}
                    choices={[
                        { id: "enabled", name: "Enabled" },
                        { id: "disabled", name: "Disabled" },
                    ]}
                />
                <TextInput source="reason" />
            </SimpleForm>
        </EditDialog>
    </>
);

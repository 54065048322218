import { ThemeOptions } from "@mui/material";
import { defaultTheme } from "react-admin";

export const extendedTheme: ThemeOptions = {
    ...defaultTheme,
    palette: {
        mode: "dark",
        primary: {
            main: "#00c3ff",
            dark: "#067dfb",
            contrastText: "rgba(255,255,255,0.87)",
        },
        secondary: {
            main: "#4f4f72",
            dark: "#067dfb",
        },
        background: {
            default: "#1f1d47",
            paper: "#37385f",
        },
        divider: "#4f4f72",
        text: {
            secondary: "#d3d3ef",
            disabled: "rgba(211,211,239,0.5)",
        },
    },
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                color: "transparent",
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    background: "linear-gradient(180deg, #0eb4fd 0%, #067dfb 100%)",
                    border: 0,
                    color: "white",
                },
                containedSecondary: {
                    background: "linear-gradient(180deg, #4f4f72 0%, rgba(79, 79, 114, 0.5) 100%)",
                    border: 0,
                    color: "white",
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
};

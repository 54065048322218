import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EmailField,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    SelectInput,
    TextInput,
} from "react-admin";
import Money from "@coinweb/bigint-money";
import { ActionOption, EgressAction, EgressCryptoRecord, EgressFiatRecord } from "../../res/types";
import { getTokenNameByHashId, removeExponentSign } from "../../res/utils";
import UndoIcon from "@mui/icons-material/Undo";
import DoneIcon from "@mui/icons-material/Done";
import BulkActions from "../../components/BulkActions";
import { EgressExpand } from "./Expand";
import bigInt from "big-integer";

const bulkActions: ActionOption<EgressAction>[] = [
    {
        type: "sufix",
        action: "done",
        title: "Done",
        content: "Are you sure you want to mark this egress as done?",
        buttonIcon: <DoneIcon />,
        buttonLabel: "Done",
    },
    {
        type: "sufix",
        action: "refund",
        title: "Refund",
        content: "Are you sure you want to refund the egress?",
        buttonIcon: <UndoIcon />,
        buttonLabel: "Refund",
    },
];

const filters = [
    <TextInput key="email" label="Email" source="email@~~" />,
    <SelectInput
        key="status"
        label="Status"
        source="status"
        choices={[
            { id: "started", name: "started" },
            { id: "registered", name: "registered" },
            { id: "ui-sent", name: "ui-sent" },
            { id: "confirmed", name: "confirmed" },
            { id: "transferred", name: "transferred" },
            { id: "refunded", name: "refunded" },
            { id: "failed", name: "failed" },
        ]}
    />,
    <NumberInput key="transaction" label="Transaction ID" source="transaction" />,
    <DateInput label="From" key="after" source="after" alwaysOn />,
    <DateInput label="To" key="before" source="before" alwaysOn />,
];

export const EgressCryptoList = () => (
    <List sort={{ field: "created_at", order: "DESC" }} filters={filters}>
        <Datagrid
            rowClick="expand"
            bulkActionButtons={<BulkActions actions={bulkActions} />}
            expand={<EgressExpand />}
        >
            <NumberField label="ID" source="transaction" />
            <EmailField source="email" />
            <DateField source="created_at" showTime />
            <FunctionField
                label="From"
                render={(record: EgressCryptoRecord) => {
                    const decodedAmount = bigInt(
                        record.source.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency =
                        record.source.type === "coin"
                            ? record.source.coin
                            : getTokenNameByHashId(record.source.token);

                    const cryptoAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .format();
                    return `${cryptoAmount} ${currency}`;
                }}
            />
            <FunctionField
                label="To"
                render={(record: EgressCryptoRecord) => {
                    const decodedAmount = bigInt(
                        record.source.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency = record.target.currency;
                    const rate = removeExponentSign(record.source.exchange_rate, currency);

                    const cryptoAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .multiply(rate)
                        .format();
                    return `${cryptoAmount} ${currency}`;
                }}
            />
            <ChipField source="status" />
        </Datagrid>
    </List>
);

export const EgressFiatList = () => (
    <List sort={{ field: "created_at", order: "DESC" }} filters={filters}>
        <Datagrid
            rowClick="expand"
            bulkActionButtons={<BulkActions actions={bulkActions} />}
            expand={<EgressExpand />}
        >
            <NumberField label="ID" source="transaction" />
            <EmailField source="email" />
            <DateField source="created_at" showTime />
            <FunctionField
                label="From"
                render={(record: EgressFiatRecord) => {
                    const decodedAmount = bigInt(
                        record.source.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency =
                        record.source.type === "coin"
                            ? record.source.coin
                            : getTokenNameByHashId(record.source.token);

                    const cryptoAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .format();
                    return `${cryptoAmount} ${currency}`;
                }}
            />
            <NumberField label="To" source="target.account_id" />
            <ChipField source="status" />
        </Datagrid>
    </List>
);

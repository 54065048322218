import React, { useMemo, useState } from "react";
import {
    Button,
    Confirm,
    useListContext,
    useNotify,
    useRefresh,
    useResourceContext,
    useUnselectAll,
    useUpdateMany,
} from "react-admin";
import { ActionOption } from "../res/types";

interface BulkActionsProps<T> {
    actions: ActionOption<T>[];
}
const BulkActions = <T,>({ actions }: BulkActionsProps<T>) => {
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);
    const { selectedIds } = useListContext();
    const [selectedAction, setAction] = useState<ActionOption<T> | null>(null);

    const [updateMany, { isLoading }] = useUpdateMany(
        resource,
        {
            ids: selectedIds,
            data: {},
            meta:
                selectedAction?.type === "prefix"
                    ? { component: selectedAction?.action }
                    : { action: selectedAction?.action },
        },
        {
            onSuccess: () => {
                notify(`${resource} updated`);
                refresh();
                unselectAll();
            },
            onError: () => notify(`Error: ${resource} not updated`, { type: "warning" }),
        },
    );

    const handleClick = (action: ActionOption<T>) => setAction(action);
    const handleDialogClose = () => setAction(null);

    const handleConfirm = () => {
        updateMany();
        setAction(null);
    };

    const actionOption = useMemo(
        () => actions.find((action) => action.action === selectedAction?.action),
        [actions, selectedAction],
    );

    return (
        <>
            {actions.map((action, index) => (
                <Button
                    key={index}
                    color="inherit"
                    label={action.buttonLabel}
                    onClick={() => handleClick(action)}
                >
                    {action?.buttonIcon}
                </Button>
            ))}
            {!!actionOption && (
                <Confirm
                    isOpen={Boolean(actionOption)}
                    loading={isLoading}
                    title={actionOption.title}
                    content={actionOption.content}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            )}
        </>
    );
};

export default BulkActions;

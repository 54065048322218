import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { KYCDOCREGX } from "../../res/utils";
import { downloadKYCDocument } from "../../res/cwbMethods";
import { Loading, useRecordContext } from "react-admin";
import { KycComponent } from "../../res/types";
import "./FileDialog.css";

export default function FileDialog() {
    const record = useRecordContext() as KycComponent;
    const filePath = useMemo(
        () => record.component.documents?.ref ?? "",
        [record.component.documents?.ref],
    );

    const [open, setOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filePath && open) {
            downloadKYCDocument(filePath)
                .then(({ file, type }: { file: string; type: string }) => {
                    const blob = new Blob([file], { type });
                    const fileUrl = URL.createObjectURL(blob);
                    setFileUrl(fileUrl);
                    setType(type);
                })
                .finally(() => setLoading(false));
        }
    }, [filePath, open]);

    const fileName = useMemo(
        () => (filePath ? `kyc_doc.${filePath.match(KYCDOCREGX)?.[3]}` : ""),
        [filePath],
    );

    return filePath ? (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                File
            </Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle>File</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                        }}
                    >
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                {type.match(/image/g) && (
                                    <img className="kyc-file" src={fileUrl} alt={fileName} />
                                )}
                                {type.match(/pdf/g) && (
                                    <iframe className="kyc-file" src={fileUrl} title={fileName} />
                                )}
                                {fileUrl && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        startIcon={<SaveAltIcon />}
                                        component="a"
                                        href={fileUrl}
                                        download={`${fileName}.${type.split("/")[1] ?? ""}`}
                                        target="_blank"
                                    >
                                        Download file
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    ) : null;
}

import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EmailField,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    SelectInput,
    TextField,
    TextInput,
    downloadCSV,
} from "react-admin";
import Money from "@coinweb/bigint-money";
import {
    ActionOption,
    IngressAction,
    IngressCryptoRecord,
    IngressFiatRecord,
    IngressCryptoExportRecord,
    IngressFiatExportRecord,
} from "../../res/types";
import UndoIcon from "@mui/icons-material/Undo";
import DoneIcon from "@mui/icons-material/Done";
import BulkActions from "../../components/BulkActions";
import { IngressExpand } from "./Expand";
import bigInt from "big-integer";
import { DateTime } from "luxon";
import { getTokenNameByHashId } from "../../res/utils";
// @ts-ignore
import jsonExport from 'jsonexport/dist';

const WCWEB = "0x505B5eDa5E25a67E1c24A2BF1a527Ed9eb88Bf04";
const STBLPROD = "0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b";
const STBLDEMO =  "0xc7fc0611c0079390b95c472d8b9d153c6468339e83a24d410e95e56b86c1317f";

const bulkActions: ActionOption<IngressAction>[] = [
    {
        type: "sufix",
        action: "manual_funded",
        title: "Funded",
        content: "Are you sure you want to mark this ingress as funded?",
        buttonIcon: <DoneIcon />,
        buttonLabel: "Funded",
    },
    {
        type: "sufix",
        action: "manual_refund",
        title: "Refund",
        content: "Are you sure you want to refund the ingress?",
        buttonIcon: <UndoIcon />,
        buttonLabel: "Refund",
    },
];

const filters = [
    <TextInput key="email" label="Email" source="email@~~" />,
    <SelectInput
        key="status"
        label="Status"
        source="status"
        choices={[
            { id: "crypto_intent_created", name: "crypto_intent_created" },
            { id: "crypto_intent_confirmed", name: "crypto_intent_confirmed" },
            { id: "invoice_created", name: "invoice_created" },
            { id: "invoice_confirmed", name: "invoice_confirmed" },
            { id: "coinweb_broadcasted", name: "coinweb_broadcasted" },
            { id: "coinweb_failed", name: "coinweb_failed" },
            { id: "coinweb_confirmed", name: "coinweb_confirmed" },
            { id: "timeout", name: "timeout" },
            { id: "failure", name: "failure" },
            { id: "manual_confirmed", name: "manual_confirmed" },
            { id: "manual_funded", name: "manual_funded" },
        ]}
    />,
    <NumberInput key="transaction" label="Transaction ID" source="transaction" />,
    <DateInput label="From" key="after" source="after" alwaysOn />,
    <DateInput label="To" key="before" source="before" alwaysOn />,
];

const exporterIngressCrypto = (posts: IngressCryptoExportRecord[]) => {
    const postsForExport = posts.map((post: IngressCryptoExportRecord) => {
        const { ...postForExport } = post;
        postForExport.source.amount = Number(postForExport.source.amount) / 10 ** 18;
        return postForExport;
    });
    jsonExport(postsForExport, {
    }, (err: Error, csv: string) => {
        downloadCSV(csv, 'posts');
    });
};

export const IngressCryptoList = () => (
    <List
        exporter={exporterIngressCrypto}
        sort={{ field: "created_at", order: "DESC" }}
        filters={filters}
    >
        <Datagrid
            rowClick="expand"
            bulkActionButtons={<BulkActions actions={bulkActions} />}
            expand={<IngressExpand />}
        >
            <NumberField label="ID" source="transaction" />
            <EmailField source="email" />
            <DateField source="created_at" showTime />
            <FunctionField
                label="From"
                render={(record: IngressCryptoRecord) => {
                    const decodedAmount = bigInt(
                        record.source.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency = record?.source?.coin;

                    const cryptoAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .format();
                    return `${cryptoAmount} ${currency}`;
                }}
            />
            <FunctionField
                label="To"
                render={(record: IngressCryptoRecord) => {
                    const decodedAmount = bigInt(
                        record.source.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency =
                        record.target.type === "coin"
                            ? record.target.coin
                            : getTokenNameByHashId(record.target.token);
                    const rate = 1;

                    const cryptoAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .multiply(rate)
                        .format();
                    return `${cryptoAmount} ${currency}`;
                }}
            />
            <ChipField source="status" />
        </Datagrid>
    </List>
);

const exporterIngressFiat = (posts: IngressFiatExportRecord[]) => {
    const postsForExport = posts.map((post: IngressFiatExportRecord) => {
        const { ...postForExport } = post;
        if(postForExport.to.token === WCWEB)
            postForExport.to.coin = 'WCWEB'
        if(postForExport.to.token === STBLPROD)
            postForExport.to.coin = 'STBL'
        if(postForExport.to.token === STBLDEMO)
            postForExport.to.coin = 'STBL'
        postForExport.to.amount = Number(postForExport.to.amount) / 10 ** 18;
        postForExport.from.amount = postForExport.from.amount / 100;
        return postForExport;
    });
    jsonExport(postsForExport, {
    }, (err: Error, csv: string) => {
        downloadCSV(csv, 'posts');
    });
};

export const IngressFiatList = () => (
    <List
        exporter={exporterIngressFiat}
        sort={{ field: "created_at", order: "DESC" }}
        filters={filters}
    >
        <Datagrid
            rowClick="expand"
            bulkActionButtons={<BulkActions actions={bulkActions} />}
            expand={<IngressExpand />}
        >
            <EmailField source="email" />
            <TextField label="ID" source="id" />
            <TextField label="User" source="user" />
            <TextField label="IP" source="ip" />

            <FunctionField
                label="Time ago"
                render={(record: IngressFiatRecord) => {
                    return DateTime.fromISO(record.created_at).toRelative();
                }}
            />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            <FunctionField
                label="From"
                render={(record: IngressFiatRecord) => {
                    const { amount, currency } = record.from;
                    const fiatAmount = new Money(amount, currency).divide(`${10 ** 2}`).format();
                    return `${fiatAmount} ${currency}`;
                }}
            />
            <FunctionField
                label="To"
                render={(record: IngressFiatRecord) => {
                    const decodedAmount = bigInt(
                        record.to.amount.toString().replace("0x", ""),
                        16,
                    ).toString();
                    const currency =
                        record.to.type === "coin"
                            ? record.to.coin
                            : getTokenNameByHashId(record.to.token);
                    const rate = 1;

                    const fiatAmount = new Money(decodedAmount, currency)
                        .divide(`${10 ** 18}`)
                        .multiply(rate)
                        .format();
                    return `${fiatAmount} ${currency}`;
                }}
            />

            <ChipField source="state" />
        </Datagrid>
    </List>
);

import { CreateDialog } from "@react-admin/ra-form-layout";
import React from "react";
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    PasswordInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";
import { ActionOption, OperatorsAction } from "../../res/types";
import UndoIcon from "@mui/icons-material/Undo";
import BulkActions from "../../components/BulkActions";

const bulkActions: ActionOption<OperatorsAction>[] = [
    {
        type: "sufix",
        action: "reset",
        title: "Reset",
        content: "Are you sure you want to reset the password?",
        buttonIcon: <UndoIcon />,
        buttonLabel: "Reset",
    },
];

const filters = [<TextInput key="email" label="Email" source="email@~~" />];

export const OperatorsList = () => (
    <>
        <List filters={filters} hasCreate>
            <Datagrid bulkActionButtons={<BulkActions actions={bulkActions} />}>
                <NumberField label="ID" source="id" />
                <TextField source="email" />
                <TextField source="role" />
                <DateField source="disabled_at" showTime />
            </Datagrid>
        </List>
        <CreateDialog>
            <SimpleForm>
                <TextInput source="email" validate={required()} />
                <SelectInput
                    source="role"
                    validate={required()}
                    choices={[
                        { id: "admin", name: "Admin" },
                        { id: "operator", name: "Operator" },
                    ]}
                />
                <PasswordInput source="password" />
            </SimpleForm>
        </CreateDialog>
    </>
);

import Money from "@coinweb/bigint-money";
import { Resource } from "./dataProvider";
export const KYCDOCREGX = new RegExp(/^(.\S+\/)+(.\S+)\.(.\S+)$/i);

export function getPKforResource(resource: Resource) {
    switch (resource) {
        case "egress/crypto":
            return "transaction";
        case "egress/fiat":
            return "transaction";
        case "ingress/crypto":
            return "transaction";
        case "ingress/fiat":
            return "id";
        case "whitelist/emails":
            return "email";
        case "whitelist/domains":
            return "domain";
        default:
            return "id";
    }
}
export function removeExponentSign(value: number, currency: string) {
    const formattedValue = String(value).toLowerCase();
    const valueHasExponentIndex = formattedValue.indexOf("e");

    return valueHasExponentIndex !== -1
        ? new Money(formattedValue.slice(0, valueHasExponentIndex), currency).divide(
              `${10 ** Number(formattedValue.slice(valueHasExponentIndex + 2))}`,
          )
        : new Money(formattedValue, currency);
}

export function getTokenNameByHashId(hashId: string) {
    hashId = hashId?.toLowerCase();
    switch (hashId) {
        case "0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b":
        case "0xc7fc0611c0079390b95c472d8b9d153c6468339e83a24d410e95e56b86c1317f":
            return "STBL";
        default:
            return "Unknown token";
    }
}

import React, { useMemo } from "react";
import { Datagrid, FunctionField, TextField, useRecordContext } from "react-admin";
import { IngressCryptoRecord, IngressFiatRecord } from "../../res/types";
import bigInt from "big-integer";
import Money from "@coinweb/bigint-money";
import { isObject } from "lodash";
import { getTokenNameByHashId } from "../../res/utils";

export const IngressExpand = () => {
    const record = useRecordContext() as IngressCryptoRecord | IngressFiatRecord;

    const fields = useMemo(
        () =>
            Object.keys(record.metadata ?? {}).map((field: string) => {
                const metadata = record.metadata as IngressFiatRecord["metadata"]; // Type assertion
                return (
                    !isObject(metadata[field]) && (
                        <TextField
                            key={field}
                            label={field.replace(/_/gi, " ")}
                            source={`metadata.${field}`}
                            sortable={false}
                        />
                    )
                );
            }),
        [record.metadata],
    );
    const rejectionFields = useMemo(
        () =>
            Object.keys(record.rejection ?? {}).map((field) => (
                <TextField
                    key={field}
                    label={field.replace(/_/gi, " ")}
                    source={`rejection.${field}`}
                    sortable={false}
                />
            )),
        [record.rejection],
    );
    const broadcastFields = useMemo(
        () =>
            Object.keys(record.metadata.broadcast ?? {}).map((field) => {
                if (field === "wallet_fees" || field === "network_fees") {
                    return (
                        <FunctionField
                            key={field}
                            label={field}
                            sortable={false}
                            render={(record: IngressFiatRecord) => {
                                const decodedAmount = bigInt(
                                    record.metadata.broadcast[field].toString().replace("0x", ""),
                                    16,
                                ).toString();
                                const currency =
                                    record.to.type === "coin"
                                        ? record.to.coin
                                        : getTokenNameByHashId(record.to.token);
                                const rate = 1;

                                const fiatAmount = new Money(decodedAmount, currency)
                                    .divide(`${10 ** 18}`)
                                    .multiply(rate)
                                    .format();
                                return `${fiatAmount} ${currency}`;
                            }}
                        />
                    );
                } else {
                    return (
                        JSON.stringify(field) !== "{}" && (
                            <TextField
                                sortable={false}
                                key={field}
                                label={field.replace(/_/gi, " ")}
                                source={`metadata.broadcast.${field}`}
                            />
                        )
                    );
                }
            }),
        [record.metadata.broadcast],
    );

    return (
        <Datagrid data={[record]} total={1} bulkActionButtons={false}>
            {fields}
            {rejectionFields}
            {broadcastFields}
        </Datagrid>
    );
};

import React from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    DateTimeInput,
    List,
    NumberField,
    required,
    SimpleForm,
} from "react-admin";
import { CreateDialog } from "@react-admin/ra-form-layout";
import { ActionOption, MaintenanceAction } from "../../res/types";
import BulkActions from "../../components/BulkActions";

const bulkActions: ActionOption<MaintenanceAction>[] = [
    {
        type: "prefix",
        action: "toggle",
        title: "Toggle",
        content: "Are you sure you want to enable the maintenance record?",
        buttonLabel: "Toggle",
    },
    {
        type: "prefix",
        action: "remove",
        title: "Remove",
        content: "Are you sure you want to remove the maintenance record?",
        buttonLabel: "Remove",
    },
];

export const MaintenanceList = () => (
    <>
        <List hasCreate>
            <Datagrid bulkActionButtons={<BulkActions actions={bulkActions} />}>
                <NumberField label="ID" source="id" />
                <BooleanField source="enabled" />
                <DateField source="since" showTime />
                <DateField source="until" showTime />
            </Datagrid>
        </List>
        <CreateDialog>
            <SimpleForm>
                <BooleanInput source="enabled" />
                <DateTimeInput source="since" validate={required()} />
                <DateTimeInput source="until" validate={required()} />
            </SimpleForm>
        </CreateDialog>
    </>
);

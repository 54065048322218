import { HttpError } from "react-admin";
import { API_URL, AUTH_KEY } from "./cwbMethods";

type User = {
    username: string;
    password: string;
};

const authProvider = {
    login: ({ username, password }: User) => {
        const request = new Request(`${API_URL}/login`, {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((token: string) => {
                localStorage.setItem(AUTH_KEY, token);
            })
            .catch(() => {
                throw new Error("Network error");
            });
    },
    checkError: (error: HttpError) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(AUTH_KEY);
            return Promise.reject({ redirectTo: "/credentials-required" });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => (localStorage.getItem(AUTH_KEY) ? Promise.resolve() : Promise.reject()),
    logout: () => {
        localStorage.removeItem(AUTH_KEY);
        return Promise.resolve();
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
};

export default authProvider;

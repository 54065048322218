import React, { useState } from "react";
import {
    Button,
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EmailField,
    FunctionField,
    List,
    SelectInput,
    TextInput,
} from "react-admin";
import { ActionOption, KYCRecord, KycAction } from "../../res/types";
import { KycExpand } from "./Expand";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BulkActions from "../../components/BulkActions";
import { KYCReject } from "./Reject";

const bulkActions: ActionOption<KycAction>[] = [
    {
        type: "sufix",
        action: "approve",
        title: "Approve KYC",
        content: "Are you sure you want to approve the KYC for this user?",
        buttonIcon: <ThumbUpOffAltIcon />,
        buttonLabel: "Approve",
    },
];

const filters = [
    <TextInput key="email" label="Email" source="email@~~" />,
    <SelectInput
        key="status_kyc"
        label="Status"
        source="status_kyc"
        choices={[
            { id: "open", name: "open" },
            { id: "partially_rejected", name: "partially_rejected" },
            { id: "rejected", name: "rejected" },
            { id: "approved", name: "approved" },
            { id: "incomplete", name: "incomplete" },
        ]}
    />,
    <DateInput label="From" key="after" source="after" alwaysOn />,
    <DateInput label="To" key="before" source="before" alwaysOn />,
];

export const KycList = () => {
    const [nestedRecord, setNestedRecord] = useState<KYCRecord | null>(null);
    return (
        <>
            <List sort={{ field: "created_at", order: "DESC" }} filters={filters}>
                <Datagrid
                    rowClick="expand"
                    bulkActionButtons={<BulkActions actions={bulkActions} />}
                    expand={<KycExpand />}
                >
                    <EmailField source="email" />
                    <DateField source="created_at" showTime />
                    <ChipField source="status" sortable={false} />
                    <FunctionField
                        label="Action"
                        source="action"
                        render={(nestedRecord: KYCRecord) =>
                            ["open", "partially_rejected", "approved"].includes(
                                nestedRecord.status,
                            ) ? (
                                <Button
                                    startIcon={<ThumbDownOffAltIcon />}
                                    label="Reject"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setNestedRecord(nestedRecord);
                                    }}
                                />
                            ) : null
                        }
                    />
                </Datagrid>
            </List>
            <KYCReject record={nestedRecord} onClose={() => setNestedRecord(null)} />
        </>
    );
};

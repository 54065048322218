import React, { useRef, useState } from "react";
import { Drawer, Grid, Box, Typography } from "@mui/material";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import {
    Button,
    Form,
    SelectInput,
    TextInput,
    useNotify,
    useRefresh,
    useResourceContext,
    useUpdate,
} from "react-admin";
import { KYCRecord } from "../../res/types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
type KYCReject = {
    record: KYCRecord | null;
    onClose: () => void;
};

export function KYCReject(props: KYCReject) {
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const counter = useRef(0);
    const [components, setComponents] = useState<string[]>([]);
    const [update, { isLoading }] = useUpdate(
        resource,
        {
            id: props.record?.id,
            meta: {
                action: "reject",
            },
        },
        {
            onSuccess: () => {
                refresh();
                props.onClose();
            },
            onError: (error) =>
                notify((error as string) || "Something went wrong", {
                    type: "error",
                }),
        },
    );

    function addComponents() {
        setComponents((c) => {
            const components = [...c];
            components.push(`c-${counter.current++}`);
            return components;
        });
    }

    function removeComponent(componentId: string) {
        setComponents((c) => {
            const components = [...c];
            components.splice(
                components.findIndex((c) => c === componentId),
                1,
            );
            return components;
        });
    }

    function submit(formData: any) {
        const { reason, ...other } = formData;
        const updateComponents = Object.entries(other)
            .filter(([name]) => components.find((component) => name.includes(component)))
            .reduce<any[]>((prev, current) => {
                const [key, value] = current;
                if (!value) return prev;
                const [, index, name] = key.split("-");
                prev[Number(index)] = { ...prev[Number(index)], [name]: value };
                return prev;
            }, [])
            .filter(Boolean);

        update(resource, {
            data: { components: updateComponents, reason: components.length ? undefined : reason },
        });
    }

    function resetComponents() {
        setComponents([]);
        counter.current = 0;
    }
    return (
        <Drawer
            open={!!props.record}
            anchor="right"
            onClose={() => {
                resetComponents();
                props.onClose();
            }}
        >
            <Form onSubmit={submit}>
                <Box padding={4}>
                    <Typography mb={3}>Reject KYC</Typography>
                    <Grid container spacing={2} maxWidth={420} alignItems="stretch">
                        {!components.length && (
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    required
                                    label="Reason"
                                    source="reason"
                                    resource={resource}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                label="add"
                                onClick={addComponents}
                                startIcon={<AddCircleIcon />}
                            />
                        </Grid>
                        {components.map((componentId) => (
                            <Box
                                key={componentId}
                                padding={3}
                                boxShadow={3}
                                style={{
                                    backgroundColor: "#0003",
                                    borderRadius: 5,
                                    width: "100%",
                                    marginTop: 10,
                                }}
                            >
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        required
                                        label="Reason"
                                        source={`${componentId}-reason`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectInput
                                        fullWidth
                                        required
                                        label="Component"
                                        source={`${componentId}-component`}
                                        choices={props.record?.components.map((component) => ({
                                            id: component.component,
                                            name: component.component,
                                        }))}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        label="Remove"
                                        onClick={() => removeComponent(componentId)}
                                    />
                                </Grid>
                            </Box>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                disabled={isLoading}
                                variant="contained"
                                label="Reject"
                                startIcon={<ThumbDownOffAltIcon />}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Form>
        </Drawer>
    );
}
